import { useNavigate } from 'react-router-dom';
import entities from '@shared/entities';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useIsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery, useGetFarmSeasonPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useGetCurrentRenewalDiscountQuery } from '@modules/billing/api/billingApi';
import { formatDateWithTranslatedMonth } from '@shared/utils/formatDateWithTranslatedMonth';
import { useTranslation } from 'react-i18next';
import { getHomePageStatus } from './getHomePageStatus';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { useGetNextFarmSeasonQuery } from '@modules/yoy/api/yoyApi';

export const useHomePageMessage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { farmer, isLoading: isLoadingFarmer } = useGetFarmer();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { activeSeason, isLoading: isLoadingActiveSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const {
        data: nextSeason,
        isLoading: isLoadingNextSeason,
        isFetching: isFetchingNextSeason,
    } = useGetNextFarmSeasonQuery({
        farmSeasonId: currentSeasonId,
    });

    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const isEncodingDone = useIsEncodingDoneGuard();

    const { data: farmPaymentStatus, isLoading: isLoadingPaymentStatus } = useGetFarmPaymentStatusQuery({
        farmId: currentSeason?.farm_id,
    });
    const { data: nextFarmSeasonPaymentStatus, isLoading: isLoadingNextFarmSeasonPaymentStatus } =
        useGetFarmSeasonPaymentStatusQuery({ farmSeasonId: nextSeason?.id });
    const { data: discount, isLoading: isCurrentRenewalDiscountLoading } = useGetCurrentRenewalDiscountQuery({
        farmId: currentSeason?.farm_id,
    });
    const { data: partnerInfo, isLoading: isLoadingPartnerInfo } = useGetPartnerInfoQuery({ userId: farmer?.id });

    const isPartnerPrepaying = !!partnerInfo?.is_partner_prepaying;
    const hasResultsPublished = currentSeason?.has_results_published;
    const hasToPayForBaseline = !!(
        farmPaymentStatus?.status === 'deferred' &&
        hasResultsPublished &&
        !isPartnerPrepaying
    );

    const isLoading =
        isLoadingFarmer ||
        currentSeasonLoading ||
        isLoadingActiveSeason ||
        isLoadingNextSeason ||
        isFetchingNextSeason ||
        progressState.isLoading ||
        isLoadingPaymentStatus ||
        isLoadingNextFarmSeasonPaymentStatus ||
        isCurrentRenewalDiscountLoading ||
        isLoadingPartnerInfo;

    const handleClick = (path: string) => navigate(path);

    const homePageStatus = getHomePageStatus({
        isSummaryValidated: !!currentSeason?.summary_validated_at,
        farmPaymentStatus: farmPaymentStatus?.status,
        hasResultsPublished: !!currentSeason?.has_results_published,
        isNextSeasonOpened: nextSeason?.season_id !== null,
        hasPaidNextSeason: !!nextFarmSeasonPaymentStatus?.paid_at,
        isPartnerPrepaying,
        isEncodingDone: !!isEncodingDone,
        isHistoryOngoing: progressState?.getBySlug(encodingRoutesConfig.HISTORY)?.status === 'ongoing',
        hasStartedEncoding: progressState?.getBySlug(encodingRoutesConfig.PREPARATION)?.status === 'done',
    });

    const getMessage = () => {
        if (homePageStatus === 'SELF_ENCODING_NOT_STARTED')
            return {
                title: 'homepage.messages.ready-to-start.title',
                description: 'homepage.messages.ready-to-start.description',
                action: {
                    label: 'homepage.messages.ready-to-start.cta',
                    link: `/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`,
                },
            };

        if (homePageStatus === 'SELF_ENCODING_ONGOING') {
            const pathToRedirectTo =
                nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
                    ? getRouteMatchingStep(nextStepToComplete?.slug)
                    : null;

            return {
                title: 'homepage.messages.encoding-pending.title',
                description: 'homepage.messages.encoding-pending.description',
                action: {
                    label: 'homepage.messages.encoding-pending.cta',
                    link: `/${currentSeasonId}/${pathToRedirectTo ?? encodingRoutesConfig.PREPARATION}`,
                },
            };
        }
        if (homePageStatus === 'RESULTS_READY_DEFERRED_PAYMENT') {
            return {
                title: 'homepage.messages.deferred-payment.title',
                description: 'homepage.messages.deferred-payment.description',
                descriptionValues: {
                    year: currentSeason?.harvest_year,
                },
                action: {
                    label: 'homepage.messages.deferred-payment.cta',
                    link: `/${currentSeason?.id}/payment/pricing`,
                },
            };
        }
        if (homePageStatus === 'RESULTS_READY_RENEWAL_PAYMENT') {
            return {
                title: 'homepage.messages.renewal.title',
                description: discount
                    ? 'homepage.messages.renewal-discount.description'
                    : 'homepage.messages.renewal.description',
                descriptionValues: {
                    discount_amount: discount?.value,
                    discount_end_date: discount?.end_date && formatDateWithTranslatedMonth(discount?.end_date, t),
                },
                action: {
                    label: 'homepage.messages.renewal.cta',
                    link: `/${currentSeasonId}/payment/pricing`,
                },
            };
        }

        if (homePageStatus === 'RESULTS_READY_NO_PAYMENT') {
            return {
                title: 'homepage.messages.results-ready.title',
                description: 'homepage.messages.results-ready.description',
                action: {
                    label: 'homepage.messages.results-ready.cta',
                    link: `/${currentSeasonId}/results`,
                },
            };
        }

        if (homePageStatus === 'PAYMENT_DONE_NEXT_SEASON_CLOSED') {
            return {
                title: 'homepage.messages.next-season-closed.title',
                description: 'homepage.messages.next-season-closed.description',
                action: {
                    label: 'homepage.messages.next-season-closed.cta',
                    link: `/${currentSeasonId}/results`,
                },
            };
        }

        if (homePageStatus === 'RESULTS_BEING_REVIEWED') {
            return {
                title: 'homepage.messages.results-being-reviewed.title',
                description: 'homepage.messages.results-being-reviewed.description',
            };
        }

        if (homePageStatus === 'NEXT_SEASON_OPEN') {
            return {
                title: 'homepage.messages.next-season-opened.title',
                description: 'homepage.messages.next-season-opened.description',
                descriptionValues: { harvestYear: activeSeason?.harvest_year },
                action: {
                    label: 'homepage.messages.next-season-opened.cta',
                    link: `/${activeSeason?.id}/`,
                },
            };
        }

        if (homePageStatus === 'SELF_ENCODING_DONE') {
            return {
                title: 'homepage.messages.self-encoding-done.title',
                description: 'homepage.messages.self-encoding-done.description',
            };
        }

        return {
            title: '',
            description: '',
        };
    };

    const message = getMessage();

    return { isLoading, message, handleClick, hasToPayForBaseline };
};
