import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { ScrollSection } from '@soil-capital/ui-kit/components';
import { IconOrganicFertilisation } from '@soil-capital/ui-kit/icons';
import { OrganicFertilisersListT } from '../FieldCropSummaryActionPage.types';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';
import { formatNumber } from '@shared/utils';

export const OrganicFertilisingSummary = ({
    fertilisersList,
}: {
    fertilisersList: OrganicFertilisersListT | undefined;
}) => {
    const { t } = useTranslation();
    const { classes, cx } = useFieldCropSummaryActionPageStyle();

    return (
        <ScrollSection spacingX={2} className={classes.container}>
            <div className={classes.containerHeader}>
                <IconOrganicFertilisation data-testid="icon-organic-fertilisation" />
                <Typography variant="caps">
                    {t('encoding-technical-itinerary.field-crop-summary.organic-fertilising')}
                </Typography>
            </div>
            {fertilisersList?.length && fertilisersList.length > 0 ? (
                <table>
                    <tr className={cx(classes.summaryHeader)}>
                        <th style={{ width: 250 }}>
                            <Typography variant="caps">
                                {t('encoding-technical-itinerary.field-crop-summary.product')}
                            </Typography>
                        </th>
                        <th style={{ width: 150 }}>
                            <Typography variant="caps">
                                {t('encoding-technical-itinerary.field-crop-summary.quantity')}
                            </Typography>
                        </th>
                    </tr>
                    <div className={classes.summaryTable}>
                        {fertilisersList?.map((fertiliser, index) => (
                            <tr key={index} className={classes.summaryRow}>
                                <td width={250}>
                                    <TextEllipsisTooltip
                                        text={t(fertiliser.product)}
                                        typographyProps={{ variant: 'body2' }}
                                    />
                                </td>
                                <td width={150}>
                                    <Typography variant="body2">
                                        {formatNumber(fertiliser.quantity)}{' '}
                                        {t(`constants.unit.${fertiliser.unit.toLowerCase()}`)}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </div>
                </table>
            ) : (
                <></>
            )}
        </ScrollSection>
    );
};
