import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import useFieldCropSummaryActionPageStyle from '../FieldCropSummaryActionPage.style';
import { Accordion, ScrollSection } from '@soil-capital/ui-kit/components';
import { IconMineralFertilisation } from '@soil-capital/ui-kit/icons';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';
import { MineralFertilisersListT, TotalCamgsT, TotalNpkT } from '../FieldCropSummaryActionPage.types';
import { formatNumber } from '@shared/utils';

export const MineralFertilisingSummary = ({
    fertilisersList,
    totalNpk,
    totalCamgs,
}: {
    fertilisersList: MineralFertilisersListT | undefined;
    totalNpk: TotalNpkT | undefined;
    totalCamgs: TotalCamgsT | undefined;
}) => {
    const { t } = useTranslation();
    const { classes, cx } = useFieldCropSummaryActionPageStyle();

    return (
        <ScrollSection spacingX={2} className={classes.container}>
            <Accordion
                className={cx(classes.container, classes.accordion)}
                Header={
                    <Accordion.Header className={classes.accordionHeader}>
                        <div className={classes.accordionHeaderContent}>
                            <div className={classes.containerHeader}>
                                <IconMineralFertilisation data-testid="icon-mineral-fertilisation" />
                                <Typography variant="caps">
                                    {t('encoding-technical-itinerary.field-crop-summary.mineral-fertilising')}
                                </Typography>
                            </div>
                            <tr className={cx(classes.summaryHeader)}>
                                <th style={{ width: 200 }} className={cx(classes.productCell, 'hiddenCell')}>
                                    <Typography variant="caps">
                                        {t('encoding-technical-itinerary.field-crop-summary.product')}
                                    </Typography>
                                </th>
                                <th style={{ width: 150 }}>
                                    <Typography variant="caps">{t('constants.unit.n-p-k')}</Typography>
                                </th>
                                <th style={{ width: 150 }}>
                                    <Typography variant="caps">{t('constants.unit.ca-mg-s')}</Typography>
                                </th>
                            </tr>
                            <tr className={classes.accordionFooter}>
                                <td style={{ width: 200 }}>
                                    <Typography variant="h3">
                                        {t('encoding-technical-itinerary.field-crop-summary.total')}
                                    </Typography>
                                </td>
                                <td style={{ width: 150 }}>
                                    <Typography variant="h3">
                                        {formatNumber(totalNpk?.n)}-{formatNumber(totalNpk?.p)}-
                                        {formatNumber(totalNpk?.k)}
                                    </Typography>
                                </td>
                                <td style={{ width: 150 }}>
                                    <Typography variant="h3">
                                        {formatNumber(totalCamgs?.ca)}-{formatNumber(totalCamgs?.mg)}-
                                        {formatNumber(totalCamgs?.s)}
                                    </Typography>
                                </td>
                            </tr>
                        </div>
                    </Accordion.Header>
                }
                Content={
                    <Accordion.Content className={classes.accordionContent}>
                        <table className={classes.summaryTable}>
                            {fertilisersList?.map((fertiliser, index) => (
                                <tr key={index} className={classes.summaryRow}>
                                    <td width={200}>
                                        <TextEllipsisTooltip
                                            text={fertiliser.product}
                                            typographyProps={{ variant: 'body2' }}
                                        />
                                    </td>
                                    <td width={150}>
                                        {(fertiliser.n > 0 || fertiliser.p > 0 || fertiliser.k > 0) && (
                                            <Typography variant="body2">
                                                {formatNumber(fertiliser.n)}-{formatNumber(fertiliser.p)}-
                                                {formatNumber(fertiliser.k)}
                                            </Typography>
                                        )}
                                    </td>
                                    <td width={150}>
                                        {(fertiliser.ca > 0 || fertiliser.mg > 0 || fertiliser.s > 0) && (
                                            <Typography variant="body2">
                                                {formatNumber(fertiliser.ca)}-{formatNumber(fertiliser.mg)}-
                                                {formatNumber(fertiliser.s)}
                                            </Typography>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </Accordion.Content>
                }
                defaultExpanded
                disableGutters
            />
        </ScrollSection>
    );
};
