import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import season from '@shared/entities/season';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { skipToken } from '@reduxjs/toolkit/query';

export const useCheckTotalAreaWithPreviousSeason = () => {
    const { currentSeasonId } = useCurrentSeasonId();

    const { fieldState } = useGetFarmSeasonFields();
    const hasFields = fieldState.list.length > 0;

    return season.useCheckTotalAreaWithPreviousSeason(
        !hasFields || fieldState.isLoading || fieldState.isFetching ? skipToken : { farmSeasonId: currentSeasonId },
    );
};
