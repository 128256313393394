import { NavigateFunction } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DisplayPage } from '@modules/encoding/shared/components/DisplayPage/DisplayPage';
import { Button } from '@soil-capital/ui-kit/components';
import { IconArrowLeft, IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { useFieldCropSummaryActionPageLogic } from './useFieldCropSummaryActionPage.logic';
import { useFieldCropSummaryActionPageData } from './useFieldCropSummaryActionPage.data';
import { CoverCroppingSummary } from './CoverCroppingSummary/CoverCroppingSummary';
import { TillageSummary } from './TillageSummary/TillageSummary';
import { SprayingSummary } from './SprayingSummary/SprayingSummary';
import { HarvestingSummary } from './HarvestingSummary/HarvestingSummary';
import { MineralFertilisingSummary } from './MineralFertilisingSummary/MineralFertilisingSummary';
import { OrganicFertilisingSummary } from './OrganicFertilisingSummary/OrganicFertilisingSummary';

export const FieldCropSummaryActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const {
        fieldName,
        cropName,
        isLoading,
        continueButtonSlug,
        isContinueDisabled,
        handleBackClick,
        handleContinueClick,
    } = useFieldCropSummaryActionPageLogic(navigate);
    const {
        hasCoverCrops,
        tillageList,
        pesticidesList,
        totalNpk,
        totalCamgs,
        mineralFertilisersList,
        organicFertilisersList,
        totalYield,
        isLoadingFieldCropSummaryData,
    } = useFieldCropSummaryActionPageData();

    return (
        <DisplayPage
            Title={
                <DisplayPage.Title variant="h2">
                    {t('encoding-technical-itinerary.field-crop-summary.title', {
                        cropName,
                        fieldName,
                    })}
                </DisplayPage.Title>
            }
            Subtitle={
                <DisplayPage.Subtitle variant="body2">
                    {t('encoding-technical-itinerary.field-crop-summary.subtitle')}
                </DisplayPage.Subtitle>
            }
            Header={
                <DisplayPage.Header>
                    <div className="left-zone">
                        <Button
                            data-testid="summary-page-left-button"
                            size="large"
                            startIcon={<IconArrowLeft />}
                            variant="text"
                            onClick={handleBackClick}
                            color="white"
                        >
                            {t('constants.back')}
                        </Button>
                    </div>
                    <Button
                        size="large"
                        startIcon={<IconCheckCircle color="inherit" />}
                        variant="contained"
                        data-testid="summary-page-right-button"
                        onClick={handleContinueClick}
                        color="white"
                        disabled={isContinueDisabled}
                    >
                        {t(continueButtonSlug)}
                    </Button>
                </DisplayPage.Header>
            }
            isLoading={isLoading || isLoadingFieldCropSummaryData}
        >
            {hasCoverCrops && <CoverCroppingSummary />}

            <TillageSummary tillageList={tillageList} />

            <MineralFertilisingSummary
                fertilisersList={mineralFertilisersList}
                totalNpk={totalNpk}
                totalCamgs={totalCamgs}
            />

            <OrganicFertilisingSummary fertilisersList={organicFertilisersList} />

            <SprayingSummary pesticidesList={pesticidesList} />

            <HarvestingSummary totalYield={totalYield} />
        </DisplayPage>
    );
};
