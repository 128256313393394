import { makeStyles } from 'tss-react/mui';

export const useFieldAreaInfoStyles = makeStyles()((theme) => ({
    fieldsInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    totalArea: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.darkScale[900],
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadiusS,
    },
    warningBox: {
        border: `1px solid ${theme.palette.warning.main}`,
    },
    errorBox: {
        border: `1px solid ${theme.palette.error.main}`,
    },
}));
