import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { Card, IconButton, NumberInput } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { useMemo, useState } from 'react';
import { FieldT, TreeT } from '@shared/entities';
import { useAgroforestryDetailsFormStyles } from './AgroforestryDetailsForm.style';
import { formatNumber } from '@shared/utils';
import { useAgroforestryDetailsForm } from '../../hooks/useAgroforestryDetailsForm';

export const AgroforestryDetailsForm = ({
    field,
    updatingAgroforestry,
    agroforestry,
    translationKey,
    disabledAgroforestry,
}: {
    field?: FieldT;
    updatingAgroforestry: TreeT[];
    disabledAgroforestry: TreeT[];
    agroforestry: TreeT;
    translationKey: string;
}) => {
    const { t } = useTranslation();
    const { classes } = useAgroforestryDetailsFormStyles();
    const { readOnly, onUpdate, onDelete } = useAgroforestryDetailsForm();
    const [quantity, setQuantity] = useState<number | null>(agroforestry.quantity_per_ha);
    const [diameter, setDiameter] = useState<number | null>(agroforestry.diameter);
    const updatingAgroforestryIds = useMemo(() => updatingAgroforestry.map((el) => el.id), [updatingAgroforestry]);
    const disabledAgroforestryIds = useMemo(() => disabledAgroforestry.map((el) => el.id), [disabledAgroforestry]);

    return (
        <Card spacing={2}>
            <div className={classes.headerContainer}>
                <div>
                    <Typography display="block" className={classes.title}>
                        {t('encoding-agroforestry.form.details.title').toLocaleUpperCase()}
                    </Typography>
                    <Typography variant="h3" display="block">
                        {t(translationKey)}
                    </Typography>
                </div>
                {!disabledAgroforestryIds.includes(agroforestry.id) && (
                    <div className={classes.iconCloseZone}>
                        <IconButton
                            disabled={updatingAgroforestryIds.includes(agroforestry.id) || readOnly}
                            size="small"
                            onClick={() => {
                                onDelete({ treeId: agroforestry.id });
                            }}
                        >
                            <IconClose className={classes.iconClose} />
                        </IconButton>
                    </div>
                )}
            </div>
            <form className={classes.formDetails}>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-agroforestry.form.details.quantity.question', {
                            treeName: t(translationKey),
                        })}
                    />
                    <div className={classes.input}>
                        <NumberInput
                            fullWidth
                            min={0}
                            disabled={updatingAgroforestryIds.includes(agroforestry.id) || readOnly}
                            incrementationStep={1}
                            placeholder={t('encoding-agroforestry.form.details.quantity.placeholder')}
                            value={quantity}
                            onChange={(v) => {
                                setQuantity(v);
                                onUpdate({ ...agroforestry, quantity_per_ha: v, diameter: diameter });
                            }}
                            suffix={t('constants.unit.any-ha')}
                        />
                    </div>
                </FormRow>
                <div>
                    <Typography display="block" className={classes.title}>
                        {t('encoding-agroforestry.form.details.total-quantity', {
                            treeName: t(translationKey),
                        })}{' '}
                        {field?.area && quantity ? formatNumber(quantity * field.area, 0) : '0'}
                    </Typography>
                </div>
                <FormRow>
                    <FormQuestion question={t('encoding-agroforestry.form.details.diameter.question')} />
                    <div className={classes.input}>
                        <NumberInput
                            fullWidth
                            min={0}
                            disabled={updatingAgroforestryIds.includes(agroforestry.id) || readOnly}
                            incrementationStep={1}
                            placeholder={t('encoding-agroforestry.form.details.diameter.placeholder')}
                            value={diameter}
                            onChange={(v) => {
                                setDiameter(v);
                                onUpdate({ ...agroforestry, diameter: v, quantity_per_ha: quantity });
                            }}
                            suffix={t('constants.unit.cm')}
                        />
                    </div>
                </FormRow>
            </form>
        </Card>
    );
};
