import { useGetNeedFinalizationStep } from '@modules/encoding/modules/rotation/hooks/useNeedFinalizationStep';
import rotationRoutes from '@modules/encoding/modules/rotation/rotation.routes';
import { encodingShared } from '@modules/encoding/shared';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import entities, { ProgressT } from '@shared/entities';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useToggle } from '@shared/hooks/useToggle';
import { NavigateFunction } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCheckTotalAreaWithPreviousSeason } from '@modules/encoding/shared/hooks/useCheckTotalAreaWithPreviousSeason';
import { useTotalAreaModalLogic } from '@modules/encoding/modules/rotation/hooks/useTotalAreaModalLogic';
import { hasDecreasedArea } from '@modules/encoding/modules/rotation/helpers/totalArea';

export const usePacImportActionPageLogic = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const [track] = useTrackMutation();
    const { fieldState } = useGetFarmSeasonFields();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { farmState } = entities.farm.useState({ farmSeasonId: currentSeasonId });
    const { getFarmSnapshot } = encodingShared.useGetFarmSnapshot({ farmSeasonId: currentSeasonId });
    const {
        open: openValidateRotationModal,
        handleOpen: handleOpenValidateRotationModal,
        handleClose: onValidateRotationClose,
    } = useToggle();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const getProgressStepBySlug = (slug: ProgressT['slug']) => progressState.getBySlug(slug);

    const { needFinalization, isLoading: isLoadingFinalization } = useGetNeedFinalizationStep();

    const { data: totalAreaCheck, isLoading: isLoadingTotalAreaCheck } = useCheckTotalAreaWithPreviousSeason();
    const delta = totalAreaCheck?.total_user_area_with_pasture_diff_percent ?? 0;
    const hasDecreasedTotalArea = hasDecreasedArea(delta);
    const hasIncreasedArea = totalAreaCheck?.check_area_grow_passed === false;
    const fields = fieldState?.list;
    const hasFields = fields?.length > 0;
    const rotationProgress = getProgressStepBySlug('rotation');
    const canContinue = hasFields && !hasIncreasedArea && !isLoadingFinalization;
    const continueDisableMessage = !hasFields
        ? t('encoding-rotation.continue.add-fields')
        : hasIncreasedArea
        ? t('encoding-rotation.continue.area-increase')
        : undefined;
    const farmSnapshot = getFarmSnapshot();
    const {
        isDecreaseModalOpen,
        isIncreaseModalOpen,
        handleDecreaseModalOpen,
        handleIncreaseModalClose,
        handleDecreaseModalClose,
    } = useTotalAreaModalLogic();

    const isLoading = fieldState.isLoading;

    const onConfirm = () => {
        if (rotationProgress?.progress_done !== rotationProgress?.progress_total) {
            track({
                event: EVENTS.FIELDS_DEFINITION_COMPLETED,
                properties: {
                    fields_number: fieldState?.list?.length,
                },
            });
        }

        if (hasDecreasedTotalArea) return handleDecreaseModalOpen();

        needFinalization && rotationProgress?.progress_done !== rotationProgress?.progress_total
            ? handleOpenValidateRotationModal()
            : navigate(rotationRoutes.introduction());
    };

    const onValidateRotationWarningModalConfirm = () => {
        navigate(rotationRoutes.introduction());
    };

    const onValidateRotationWarningModalClose = () => {
        onValidateRotationClose();
    };

    const farmCoordinates = farmState.list[0]?.center?.geometry?.coordinates;

    const { readOnly } = useFarmSeasonReadOnly();

    return {
        canContinue,
        continueDisableMessage,
        farmSnapshot,
        isLoading,
        onConfirm,
        hasFields,
        fields,
        openValidateRotationModal,
        onValidateRotationWarningModalClose,
        onValidateRotationWarningModalConfirm,
        farmCoordinates,
        readOnly,
        isDecreaseModalOpen,
        isIncreaseModalOpen,
        handleIncreaseModalClose,
        handleDecreaseModalClose,
        isLoadingCheck: fieldState?.isFetching || isLoadingTotalAreaCheck,
    };
};
