import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacImportHeaderStyles = makeStyles()((theme) => ({
    harvest_year: {
        color: theme.palette.success.main,
    },
    importButton: {
        marginTop: theme.spacing(3),
    },
    helpButton: {
        marginTop: theme.spacing(2),
    },
    uploadIcon: {
        width: 16,
    },
    caption: { paddingTop: theme.spacing(3), display: 'inline-block' },
}));
