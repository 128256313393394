import entities, { StaticTreeT, TreeT } from '@shared/entities';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { encodingShared } from '@modules/encoding/shared';
import { NavigateFunction } from 'react-router-dom';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import agroforestryRoutes from '../agroforestry.routes';
import useAgroforestryParams from './useAgroforestryParams';

// Helper function to sort static data alphabetically according to the translation
export const sortDataAlphabetically = <T extends { translation_slug: string }>(data: T[], t: TFunction): T[] => {
    return [...data].sort((a, b) => {
        return t(a.translation_slug).localeCompare(t(b.translation_slug));
    });
};

export const useAgroforestryForm = ({ navigate }: { navigate: NavigateFunction }) => {
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { fieldId } = useAgroforestryParams();
    const { t } = useTranslation();
    const { readOnly } = useFarmSeasonReadOnly();

    // Get static agroforestry
    const { staticTreeState } = entities.staticTree.useState();
    const staticAgroforestryOptions = staticTreeState.list;

    // Get farm season agroforestry
    const { treeState } = entities.tree.useState({ farmSeasonId: currentSeasonId });
    const [deleteAgroforestry] = entities.tree.useDelete();
    const [createAgroforestry] = entities.tree.useCreate();
    const agroforestryData = treeState.getByFieldId(fieldId);
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const field = fieldState.getById(fieldId);

    // Sorting datas and static datas alphabetically
    const sortedStaticAgroforestry = useMemo(
        () => sortDataAlphabetically(staticAgroforestryOptions, t),
        [staticAgroforestryOptions, t],
    );
    const sortedAgroforestryData: TreeT[] = useMemo(() => {
        return [...agroforestryData].sort((a, b) => {
            const indexA: number = sortedStaticAgroforestry.findIndex((el) => el.id === a.tree_type_id);
            const indexB: number = sortedStaticAgroforestry.findIndex((el) => el.id === b.tree_type_id);
            if (indexA !== undefined && indexB !== undefined) {
                return indexA - indexB;
            } else {
                return 0;
            }
        });
    }, [agroforestryData, sortedStaticAgroforestry]);

    const selectedStaticAgroforestry = useMemo(() => {
        const sortedAgroforestryDataIds = sortedAgroforestryData.map((el) => el.tree_type_id);
        return sortedStaticAgroforestry.filter((el) => sortedAgroforestryDataIds.includes(el.id));
    }, [sortedStaticAgroforestry, sortedAgroforestryData]);

    // Compute the progress (each selected agroforestry quantity and diameter is > 0)
    const isDone =
        sortedAgroforestryData.length > 0 &&
        !sortedAgroforestryData.find(
            (el) => !el.quantity_per_ha || el.quantity_per_ha <= 0 || !el.diameter || el.diameter <= 0,
        );

    /*
     * The currently updated datas are temporarily disabled during the update process to prevent users from clicking checkboxes multiple times
     * which could cause inconsistencies between the database and the cache.
     */
    const updatingAgroforestry = useMemo(
        () => sortedAgroforestryData.filter((el) => el.id < 0),
        [sortedAgroforestryData],
    );
    const updatingStaticAgroforestry = useMemo(() => {
        const updatingIds = updatingAgroforestry.map((el) => el.tree_type_id);
        return staticAgroforestryOptions?.filter((el) => updatingIds.includes(el.id));
    }, [staticAgroforestryOptions, updatingAgroforestry]);

    const disabledAgroforestry = useMemo(
        () => sortedAgroforestryData.filter((el) => el.previous_farm_season_field_tree_id),
        [sortedAgroforestryData],
    );
    const disabledStaticAgroforestry = useMemo(() => {
        const disabledIds = disabledAgroforestry.map((el) => el.tree_type_id);
        return staticAgroforestryOptions?.filter((el) => disabledIds.includes(el.id));
    }, [disabledAgroforestry, staticAgroforestryOptions]);

    // Handlers
    const handleAgroforestryChange = useCallback(
        async (newValues: StaticTreeT[]) => {
            const newIds = new Set(newValues.map((el) => el.id));
            const oldIds = new Set(sortedAgroforestryData.map((el) => el.tree_type_id));

            const [addedId] = newIds.difference(oldIds);
            const [removedId] = oldIds.difference(newIds);

            if (removedId) {
                const removedAgroforestry = sortedAgroforestryData.find((el) => el.tree_type_id === removedId);
                if (removedAgroforestry) {
                    return await deleteAgroforestry({
                        farmSeasonId: currentSeasonId,
                        farmSeasonTreeId: removedAgroforestry.id,
                    });
                }
            } else {
                if (addedId) {
                    const addedAgroforestry = staticAgroforestryOptions.find((el) => el.id === addedId);
                    if (addedAgroforestry) {
                        return await createAgroforestry({
                            farmSeasonId: currentSeasonId,
                            body: {
                                tree_type_id: addedAgroforestry.id,
                                diameter: null,
                                farm_season_field_id: fieldId,
                                previous_farm_season_field_tree_id: null,
                                quantity: 0,
                                quantity_per_ha: null,
                            },
                        });
                    }
                }
            }
        },
        [
            sortedAgroforestryData,
            deleteAgroforestry,
            currentSeasonId,
            staticAgroforestryOptions,
            createAgroforestry,
            fieldId,
        ],
    );

    // Loading states
    const isLoading = staticTreeState.isLoading || treeState.isLoading || fieldState.isLoading;

    const handleContinue = useCallback(async () => {
        const fieldsWithAgroforestry = fieldState.list.filter((el) => el.has_agroforestry);
        if (
            fieldsWithAgroforestry.length > 1 &&
            !readOnly &&
            !sortedAgroforestryData.some((el) => el.previous_farm_season_field_tree_id)
        ) {
            navigate(agroforestryRoutes.agroforestryDuplication({ fieldId }));
        } else {
            navigate(agroforestryRoutes.introduction());
        }
    }, [fieldId, fieldState.list, navigate, readOnly, sortedAgroforestryData]);

    return {
        field,
        staticAgroforestry: sortedStaticAgroforestry,
        agroforestry: sortedAgroforestryData,
        selectedStaticAgroforestry,
        disabledAgroforestry,
        disabledStaticAgroforestry,
        updatingAgroforestry,
        updatingStaticAgroforestry,
        handleAgroforestryChange,
        handleContinue,
        isLoading,
        isDone,
        readOnly,
    };
};
