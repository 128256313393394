import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonOperations } from '@shared/hooks/useGetFarmSeasonOperations';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import { ROTATION_SOURCE_ENUM, useGetRotationSourceQuery } from '@modules/yoy/api/yoyApi';

export const usePacImportContextualPanelData = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { harvestYear, isLoading: isLoadingHarvest } = useGetCurrentHarvestYear();
    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { operationState } = useGetFarmSeasonOperations();
    const { isBaseline, baselineHarvestYear, isLoading: isLoadingBaseline } = useBaselineFarmSeason();
    const { data: rotationSource, isLoading: isLoadingRotationSource } = useGetRotationSourceQuery({
        farmSeasonId: currentSeasonId,
    });

    const isLoading =
        isLoadingHarvest ||
        fieldState.isLoading ||
        fieldCropState.isLoading ||
        operationState.isLoading ||
        isLoadingBaseline ||
        isLoadingRotationSource;

    const isRotationSourceManual = rotationSource?.source === ROTATION_SOURCE_ENUM.MANUAL;
    const showCopiedFromBaselineInfobox = !fieldState?.isFetching && isRotationSourceManual && !isBaseline;
    return {
        harvestYear,
        baselineHarvestYear,
        showCopiedFromBaselineInfobox,
        isLoading,
    };
};
