import { useToggle } from '@shared/hooks/useToggle';
import { useCheckTotalAreaWithPreviousSeason } from '@modules/encoding/shared/hooks/useCheckTotalAreaWithPreviousSeason';
import { useEffect } from 'react';
import { hasIncreasedArea } from '@modules/encoding/modules/rotation/helpers/totalArea';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const useTotalAreaModalLogic = () => {
    const { isBaseline } = useBaselineFarmSeason();

    const {
        open: isDecreaseModalOpen,
        handleOpen: handleDecreaseModalOpen,
        handleClose: handleDecreaseModalClose,
    } = useToggle();

    const {
        open: isIncreaseModalOpen,
        handleOpen: handleIncreaseModalOpen,
        handleClose: handleIncreaseModalClose,
    } = useToggle();

    const { data, isLoading } = useCheckTotalAreaWithPreviousSeason();
    const delta = data?.total_user_area_with_pasture_diff_percent ?? 0;

    const hasIncreased = hasIncreasedArea(delta);

    useEffect(() => {
        if (isBaseline || (isLoading && !!delta)) return;
        if (hasIncreased) handleIncreaseModalOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, delta, hasIncreased]);

    return {
        isDecreaseModalOpen,
        handleDecreaseModalOpen,
        handleDecreaseModalClose,
        isIncreaseModalOpen,
        handleIncreaseModalClose,
    };
};
