import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { usePacImportActionPageStyles } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/PacImportActionPage.style';
import { usePacImportActionPageLogic } from '@modules/encoding/modules/rotation/pages/PacImportActionPage/usePacImportActionPageLogic';
import ActionPage from '@modules/encoding/shared/components/ActionPage/ActionPage';
import { MapView } from '@shared/map/MapView';
import { usePacImportActionPageMap } from './usePacImportActionMap';
import { ValidateRotationConfirmModal } from '../../components/ValidateRotationConfirmModal/ValidateRotationConfirmModal';
import MapPopup from '@shared/components/MapPopup/MapPopup';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import { AreaDecreaseModal } from '@modules/encoding/modules/rotation/components/TotalArea/AreaDecreaseModal/AreaDecreaseModal';
import { AreaIncreaseModal } from '@modules/encoding/modules/rotation/components/TotalArea/AreaIncreaseModal/AreaIncreaseModal';

export const PacImportActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = usePacImportActionPageStyles();
    const {
        canContinue,
        continueDisableMessage,
        isLoading,
        onConfirm,
        hasFields,
        fields,
        openValidateRotationModal,
        onValidateRotationWarningModalClose,
        onValidateRotationWarningModalConfirm,
        farmCoordinates,
        readOnly,
        isDecreaseModalOpen,
        isIncreaseModalOpen,
        handleIncreaseModalClose,
        handleDecreaseModalClose,
        isLoadingCheck,
    } = usePacImportActionPageLogic({ navigate });
    const {
        calculateCentroid,
        polygons,
        helpText,
        drawMode,
        editMode,
        splitMode,
        hoveredFeatureFieldOverview,
        mergeMode,
    } = usePacImportActionPageMap(fields);
    const { isYoyFarmSeason } = useBaselineFarmSeason();

    return (
        <>
            <ActionPage
                isLoading={isLoading}
                classes={{ body: classes.body }}
                rightButtonProps={{
                    loading: isLoadingCheck,
                    disabled: !canContinue,
                    hoverText: continueDisableMessage,
                    onClick: onConfirm,
                    children: t('encoding-rotation.pac-file.action-page.confirm-button'),
                }}
            >
                {hasFields ? (
                    <MapView
                        hasControls
                        isDrawable
                        readOnly={readOnly}
                        coordinates={calculateCentroid(polygons)}
                        helpText={t(helpText)}
                        hasOpendata={!drawMode && !editMode && !splitMode && !mergeMode && !isYoyFarmSeason}
                    />
                ) : (
                    !isLoading && (
                        <MapView
                            hasControls
                            isDrawable
                            readOnly={readOnly}
                            coordinates={farmCoordinates}
                            helpText={t(helpText)}
                            hasOpendata={!drawMode && !editMode && !splitMode && !mergeMode && !isYoyFarmSeason}
                        />
                    )
                )}
                <MapPopup hoveredFeatureFieldOverview={hoveredFeatureFieldOverview} />
            </ActionPage>
            <ValidateRotationConfirmModal
                open={openValidateRotationModal}
                onClose={onValidateRotationWarningModalClose}
                onConfirm={onValidateRotationWarningModalConfirm}
            />
            <AreaDecreaseModal isOpen={isDecreaseModalOpen} onClose={handleDecreaseModalClose} />
            <AreaIncreaseModal isOpen={isIncreaseModalOpen} onClose={handleIncreaseModalClose} />
        </>
    );
};
