import { useState, useCallback, useMemo, useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import irrigationRoutes from '../../irrigation.routes';
import { useIrrigationRouteParams } from '../../hooks/useIrrigationParams';
import entities, { FieldT, StaticCropT } from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { PropertiesType } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanelPropertiesList/DuplicationModalLeftPanelPropertiesList';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { encodingShared } from '@modules/encoding/shared';
import { DuplicationFieldT } from '@modules/encoding/shared/components/Duplication/DuplicationModalLeftPanel/DuplicationModalLeftPanel';
import { useModalController } from '@shared/hooks/useModalController';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { fieldSelectionDuplicationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import { enqueueSnackbar } from 'notistack';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';

interface IrrigationDuplicationActionPageData {
    sourceField: DuplicationFieldT;
    excludedFieldIds: number[];
    selectedFieldIds: number[] | null;
    propertiesList: PropertiesType[];
    isLoading: boolean;
    openIrrigationDuplicationModal: boolean;
    toggleIrrigationDuplicationModal: () => void;
    onSelect: (fieldId: number) => void;
    onDeselect: (fieldId: number) => void;
    handleDuplicateClick: () => void;
    handleExceptionClick: () => void;
    onModalRightPanelCopyButtonClick: () => Promise<unknown>;
    confirmOverrideIrrigationWarningModalProps: {
        isOpen: boolean;
        data: void | null;
        onClose: ((closeData: ConfirmModalCloseDataT) => void) | null;
    };
}

export const useIrrigationDuplicationActionPageData = ({
    navigate,
}: {
    navigate: NavigateFunction;
}): IrrigationDuplicationActionPageData => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Modal state
    const [openIrrigationDuplicationModal, setOpenIrrigationDuplicationModal] = useState<boolean>(false);

    const toggleIrrigationDuplicationModal = useCallback(() => {
        setOpenIrrigationDuplicationModal((prev) => !prev);
    }, []);

    // Route parameters
    const { irrigationId, seasonId } = useIrrigationRouteParams('duplication');
    if (!seasonId || !irrigationId) {
        throw new Error('A parameter is unexpectedly missing in the irrigation duplication route');
    }

    // Entity states
    const { fieldState } = useGetFarmSeasonFields();
    const { getFieldsWithoutIrrigation } = fieldState.useGetFieldsWithoutIrrigation();
    const fieldsWithoutIrrigation = getFieldsWithoutIrrigation();
    const fieldsWithoutIrrigationIds = fieldsWithoutIrrigation.map((el) => el.id);
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: seasonId });
    const [duplicateIrrigation] = entities.irrigation.useDuplicate();
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const irrigationSourceField = irrigationState.getById(irrigationId);
    const sourceFieldId = Number(irrigationSourceField?.farm_season_field_id);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionDuplication.selectedIds);

    const { currentFarm } = encodingShared.useCurrentFarm();
    const { currentSeasonId } = useCurrentSeasonId();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId ?? 0 });
    const { getCropsAssignedToAField, fieldCropStateLoading } = cropState.useGetCropsAssignedToAField();

    // Static entities
    const { staticIrrigationMethodState } = entities.staticIrrigationMethod.useState();
    const { staticIrrigationPowerSourceState } = entities.staticIrrigationPowerSource.useState();
    const { staticIrrigationWaterSourceState } = entities.staticIrrigationWaterSource.useState();

    const irrigationMethodId = irrigationSourceField?.irrigation_method_id;
    const irrigationPowerSourceId = irrigationSourceField?.irrigation_power_source_id;
    const irrigationWaterSourceId = irrigationSourceField?.irrigation_water_source_id;

    const sourceFieldIrrigationMethod = staticIrrigationMethodState.getById(irrigationMethodId);
    const sourceFieldIrrigationPowerSource = staticIrrigationPowerSourceState.getById(irrigationPowerSourceId);
    const sourceFieldIrrigationWaterSource = staticIrrigationWaterSourceState.getById(irrigationWaterSourceId);

    // Loading state

    const sourceField: DuplicationFieldT = useMemo(() => {
        const field = fieldState.getById(sourceFieldId) as FieldT;
        const allCrops = getCropsAssignedToAField(sourceFieldId);
        const allStaticCrops = allCrops
            .map((crop) => staticCropState.getById(crop.crop_id))
            .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);

        return {
            field: field,
            snapshot: field ? getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at }) : '',
            crops: allStaticCrops?.map((c) => ({
                id: c?.id,
                name: t(`db.crop.slug-${c.slug}`),
            })),
        };
    }, [fieldState, getCropsAssignedToAField, getFieldThumbnail, sourceFieldId, staticCropState, t]);

    const isLoading =
        cropState.isLoading ||
        irrigationState.isLoading ||
        fieldState.isLoading ||
        staticCropState.isLoading ||
        staticIrrigationMethodState.isLoading ||
        staticIrrigationPowerSourceState.isLoading ||
        staticIrrigationWaterSourceState.isLoading ||
        fieldCropStateLoading ||
        !sourceField;

    // Properties list
    const propertiesList = useMemo(
        () => [
            {
                key: 'irrigation_method_id',
                keyLabel: t('encoding-irrigation.irrigation-form-action-page.method-question'),
                valueLabel: t(sourceFieldIrrigationMethod?.translation_slug || ''),
            },
            {
                key: 'irrigation_power_source_id',
                keyLabel: t('encoding-irrigation.irrigation-form-action-page.power-source-question'),
                valueLabel: t(sourceFieldIrrigationPowerSource?.translation_slug || ''),
            },
            {
                key: 'irrigation_water_source_id',
                keyLabel: t('encoding-irrigation.irrigation-form-action-page.water-source-question'),
                valueLabel: t(sourceFieldIrrigationWaterSource?.translation_slug || ''),
            },
            {
                key: 'pumping_depth',
                keyLabel: t('encoding-irrigation.irrigation-form-action-page.pumping-depth-question'),
                valueLabel: irrigationSourceField?.pumping_depth?.toString() + ' ' + t('constants.unit.meters') || '',
            },
            {
                key: 'total_amount',
                keyLabel: t('encoding-irrigation.irrigation-form-action-page.water-source-question'),
                valueLabel:
                    irrigationSourceField?.total_amount?.toString() + ' ' + t('constants.unit.milimeters') || '',
            },
        ],
        [
            t,
            sourceFieldIrrigationMethod,
            sourceFieldIrrigationPowerSource,
            sourceFieldIrrigationWaterSource,
            irrigationSourceField,
        ],
    );

    // Warning Modal Controller
    const { open: openOverrideIrrigationWarningModal, ...confirmOverrideIrrigationWarningModalProps } =
        useModalController<void, ConfirmModalCloseDataT>();

    const handleOpenOverrideIrrigationWarningModal = useCallback(
        async (fieldId: number) => {
            const irrigationField = irrigationState.getByFieldId(fieldId);
            const isNotEmpty =
                irrigationField?.horizontal_distance !== null ||
                irrigationField.irrigation_method_id !== null ||
                irrigationField.irrigation_power_source_id !== null ||
                irrigationField.irrigation_water_source_id !== null ||
                irrigationField.pumping_depth !== null ||
                irrigationField.total_amount !== null;
            if (isNotEmpty) {
                const { confirmed } = await openOverrideIrrigationWarningModal();
                return confirmed;
            }
            return true;
        },
        [irrigationState, openOverrideIrrigationWarningModal],
    );

    useEffect(() => {
        dispatch(fieldSelectionDuplicationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handlers
    const onSelect = useCallback(
        async (fieldId: number) => {
            const confirmed = await handleOpenOverrideIrrigationWarningModal(fieldId);
            if (confirmed) {
                dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldId]));
            }
        },
        [dispatch, handleOpenOverrideIrrigationWarningModal, selectedFieldIds],
    );

    const onDeselect = useCallback(
        (fieldId: number) => {
            const newSelectedFieldIds = [...selectedFieldIds].filter((el) => el !== fieldId);
            dispatch(fieldSelectionDuplicationSlice.actions.setSelectedFieldIds(newSelectedFieldIds));
        },
        [dispatch, selectedFieldIds],
    );

    const handleDuplicateClick = useCallback(() => {
        toggleIrrigationDuplicationModal();
    }, [toggleIrrigationDuplicationModal]);

    const handleExceptionClick = useCallback(() => {
        navigate(irrigationRoutes.introduction());
    }, [navigate]);

    const onModalRightPanelCopyButtonClick = useCallback(async () => {
        const response = await duplicateIrrigation({
            farmSeasonId: seasonId,
            id: irrigationId,
            body: {
                farm_season_field_ids: selectedFieldIds,
            },
        });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('encoding-irrigation.duplication.error'), { variant: 'error' });
        } else {
            await lazyGetProgress({ seasonId: currentSeasonId });
            navigate(irrigationRoutes.introduction());
        }
    }, [currentSeasonId, duplicateIrrigation, irrigationId, lazyGetProgress, navigate, seasonId, selectedFieldIds, t]);

    return {
        sourceField,
        excludedFieldIds: [...fieldsWithoutIrrigationIds, sourceField?.field?.id],
        selectedFieldIds,
        propertiesList,
        isLoading,
        openIrrigationDuplicationModal,
        toggleIrrigationDuplicationModal,
        onSelect,
        onDeselect,
        handleDuplicateClick,
        handleExceptionClick,
        onModalRightPanelCopyButtonClick,
        confirmOverrideIrrigationWarningModalProps,
    };
};
