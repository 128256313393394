import { ReactNode } from 'react';
import { Button, ButtonPropsT } from '@soil-capital/ui-kit/components';
import { IconArrowLeft, IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { Skeleton, Typography } from '@soil-capital/ui-kit/material-core';
import useActionPageStyle from './ActionPage.style';
import { DisabledSection } from '../DisabledSection/DisabledSection';
import { Tooltip } from '@soil-capital/ui-kit';

export default ({
    children,
    title,
    subtitle: subTitle,
    isLoading = false,
    leftButtonProps,
    rightButtonProps,
    errorMessage,
    className,
    classes,
    readOnly,
}: {
    children: ReactNode;
    title?: string;
    subtitle?: string;
    leftButtonProps?: Pick<ButtonPropsT, 'children' | 'onClick' | 'loading'> & { hoverText?: string };
    rightButtonProps?: Pick<ButtonPropsT, 'children' | 'onClick' | 'disabled' | 'loading'> & { hoverText?: string };
    isLoading?: boolean;
    errorMessage?: string;
    className?: string;
    classes?: {
        root?: string;
        header?: string;
        body?: string;
    };
    readOnly?: boolean;
}) => {
    const { classes: muiClasses, cx } = useActionPageStyle();
    return (
        <div className={cx(muiClasses.actionPage, classes?.root, className)} data-testid="shared-action-page">
            {/* TODO refactor this header depending on the controls needs */}
            <div className="transition-panel">
                <div className={cx(muiClasses.header, classes?.header)}>
                    <div className="left-zone">
                        {leftButtonProps && (
                            <Button
                                data-testid="shared-action-page-left-button"
                                size="large"
                                startIcon={<IconArrowLeft />}
                                variant="text"
                                onClick={leftButtonProps?.onClick}
                                loading={leftButtonProps?.loading}
                            >
                                {leftButtonProps?.children}
                            </Button>
                        )}
                    </div>
                    {errorMessage && (
                        <Typography color="error" mr={1} display="block">
                            {errorMessage}
                        </Typography>
                    )}
                    <Tooltip text={rightButtonProps?.hoverText}>
                        <Button
                            size="large"
                            startIcon={<IconCheckCircle color="inherit" />}
                            variant="contained"
                            disabled={rightButtonProps?.disabled}
                            data-testid="shared-action-page-right-button"
                            onClick={rightButtonProps?.onClick}
                            loading={rightButtonProps?.loading}
                        >
                            {rightButtonProps?.children}
                        </Button>
                    </Tooltip>
                </div>
            </div>

            {/* Body */}
            <div className={cx(muiClasses.body, `transition-panel`, classes?.body)}>
                {subTitle && (
                    <Typography variant="caption" display="block" className={muiClasses.subtitle}>
                        {subTitle?.toLocaleUpperCase()}
                    </Typography>
                )}
                {title && (
                    <Typography variant="h2" mb={2} display="block">
                        {title}
                    </Typography>
                )}
                <DisabledSection disabled={readOnly}>{children}</DisabledSection>
            </div>

            {/* Skeleton */}
            <div
                className={`${muiClasses.actionPageSkeleton} ${isLoading ? muiClasses.actionPageLoading : ''}`}
                data-testid="skeleton-section"
            >
                <div className={muiClasses.header}>
                    <div className="left-zone">
                        <Skeleton>
                            <Button size="large" startIcon={<IconArrowLeft />} variant="text">
                                Précédent
                            </Button>
                        </Skeleton>
                    </div>

                    <Skeleton>
                        <Button size="large" startIcon={<IconCheckCircle color="inherit" />} variant="contained">
                            Continuer
                        </Button>
                    </Skeleton>
                </div>

                <div className={cx(muiClasses.body)}>
                    {/* add overline */}
                    <Skeleton>
                        <Typography variant="caption" display="block" className={muiClasses.subtitle}>
                            {subTitle?.toLocaleUpperCase()}
                        </Typography>
                        <Typography variant="h2" mb={2} display="block">
                            {title}
                        </Typography>
                    </Skeleton>
                    <Skeleton variant="rectangular" className={muiClasses.childrenSkeleton} />
                    <Skeleton variant="rectangular" className={muiClasses.childrenSkeleton} />
                </div>
            </div>
        </div>
    );
};
