import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import {
    MineralFertilisersListT,
    OrganicFertilisersListT,
    PesticidesListT,
    TillageListT,
    TotalCamgsT,
    TotalNpkT,
} from './FieldCropSummaryActionPage.types';
import { formatNumber } from '@shared/utils';

export const useFieldCropSummaryActionPageData = () => {
    const { t } = useTranslation();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['fieldCropSummary']>();
    const { currentSeasonId } = useCurrentSeasonId();

    const { data: fieldCropSummary, isLoading: isLoadingFieldCropSummaryData } =
        entities.fieldCrop.useGetFieldCropSummary({
            farmSeasonId: currentSeasonId,
            id: Number(fieldCropId),
        });

    const hasCoverCrops = fieldCropSummary?.has_cover_crops;
    const tillageSummary = fieldCropSummary?.tillage_summary;
    const mineralFertilisingSummary = fieldCropSummary?.mineral_fertilising_summary;
    const organicFertilisingSummary = fieldCropSummary?.organic_fertilising_summary;
    const sprayingSummary = fieldCropSummary?.spraying_summary;
    const harvestingSummary = fieldCropSummary?.harvesting_summary;

    const tillageList: TillageListT | undefined = tillageSummary?.map((tillage) => {
        return {
            machinery: t(`db.machineries.${tillage.machinery_slug}`),
            ...tillage,
        };
    });

    const totalNpk: TotalNpkT | undefined = mineralFertilisingSummary?.total_npk;
    const totalCamgs: TotalCamgsT | undefined = mineralFertilisingSummary?.total_camgs;
    const mineralFertilisersList: MineralFertilisersListT | undefined = mineralFertilisingSummary?.fertilisers?.map(
        (fertiliser) => {
            return {
                product: `${formatNumber(fertiliser.quantity)}${t(
                    `constants.unit.${fertiliser.unit.toLowerCase()}`,
                )} ${t(fertiliser.fertiliser_slug)}`,
                ...fertiliser,
            };
        },
    );

    const organicFertilisersList: OrganicFertilisersListT | undefined = organicFertilisingSummary?.map((fertiliser) => {
        return {
            product: fertiliser.fertiliser_slug,
            ...fertiliser,
        };
    });

    const pesticidesList: PesticidesListT | undefined = sprayingSummary?.map((pesticide) => {
        return {
            product: t(`db.pesticide-type.${pesticide.pesticide_type_slug}`),
            ...pesticide,
        };
    });

    const totalYield = harvestingSummary?.yield;

    return {
        hasCoverCrops,
        tillageList,
        totalNpk,
        totalCamgs,
        mineralFertilisersList,
        organicFertilisersList,
        pesticidesList,
        totalYield,
        isLoadingFieldCropSummaryData: isLoadingFieldCropSummaryData,
    };
};
