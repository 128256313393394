import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTotalAreaStyles } from '@modules/encoding/modules/rotation/components/TotalArea/TotalArea.style';
import { useCheckTotalAreaWithPreviousSeason } from '@modules/encoding/shared/hooks/useCheckTotalAreaWithPreviousSeason';
import { useNavigate } from 'react-router-dom';
import rotationRoutes from '@modules/encoding/modules/rotation/rotation.routes';

export const AreaDecreaseModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { classes } = useTotalAreaStyles();

    const { data } = useCheckTotalAreaWithPreviousSeason();

    const handleConfirm = () => {
        onClose();
        navigate(`../${rotationRoutes.introduction()}`);
    };

    return (
        <Modal
            size="small"
            open={isOpen}
            onClose={onClose}
            onConfirm={handleConfirm}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-rotation.continue.area-decrease-modal.title')}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <WarningBox className={classes.warningBox} displayWarningIcon={false}>
                        <Trans
                            t={t}
                            i18nKey="encoding-rotation.continue.area-decrease-modal.warning"
                            values={{
                                previousTotalArea: data?.previous_season_user_area_with_pasture,
                                currentTotalArea: data?.current_season_user_area_with_pasture,
                                delta: Math.abs(data?.total_user_area_with_pasture_diff_ha ?? 0),
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        {t('encoding-rotation.continue.area-decrease-modal.confirm')}
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel>{t('constants.cancel')}</Modal.Cancel>
                    <Modal.Confirm>{t('constants.confirm')}</Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
