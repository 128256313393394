import { useFieldAreaInfoStyles } from '@modules/encoding/modules/rotation/components/FieldAreaInfo/FieldAreaInfo.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { IconInfo, IconWarning } from '@soil-capital/ui-kit/icons';
import { useCheckTotalAreaWithPreviousSeason } from '@modules/encoding/shared/hooks/useCheckTotalAreaWithPreviousSeason';
import { Skeleton, Box } from '@soil-capital/ui-kit/material-core';
import { hasDecreasedArea, hasIncreasedArea } from '@modules/encoding/modules/rotation/helpers/totalArea';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const FieldAreaInfo = () => {
    const { classes, cx } = useFieldAreaInfoStyles();
    const { t } = useTranslation();
    const { fieldState } = useGetFarmSeasonFields();
    const numberOfFields = fieldState.list.length;
    const { data, isLoading } = useCheckTotalAreaWithPreviousSeason();
    const totalArea = data?.current_season_user_area_with_pasture;
    const previousTotalArea = data?.previous_season_user_area_with_pasture;
    const delta = data?.total_user_area_with_pasture_diff_percent ?? 0;
    const { isBaseline } = useBaselineFarmSeason();

    const hasIncreased = hasIncreasedArea(delta);
    const hasDecreased = hasDecreasedArea(delta);

    if (isLoading)
        return (
            <Box className={classes.fieldsInfoContainer}>
                <Skeleton variant="text" width="30%" height="25px" />
                <Skeleton variant="text" width="40%" height="25px" />
                <Skeleton variant="text" width="20%" height="25px" />
            </Box>
        );

    return (
        <>
            <div className={classes.fieldsInfoContainer}>
                {previousTotalArea && (
                    <Typography mt={0.5} color="neutral" variant="body">
                        {t('constants.previous-year-total-area')} : {previousTotalArea} {t('constants.hectare-unit')}
                    </Typography>
                )}
                {hasDecreased && !isBaseline ? (
                    <div className={cx(classes.box, classes.warningBox)}>
                        <div className={classes.totalArea}>
                            <IconInfo color="warning" />
                            <Typography mt={0.5} variant="body">
                                {t('constants.total-area')} : {totalArea} {t('constants.hectare-unit')}
                            </Typography>
                        </div>
                        <Typography variant="body2">{t('encoding-rotation.total-area.decrease')}</Typography>
                    </div>
                ) : hasIncreased && !isBaseline ? (
                    <div className={cx(classes.box, classes.errorBox)}>
                        <div className={classes.totalArea}>
                            <IconWarning color="error" />
                            <Typography mt={0.5} variant="body">
                                {t('constants.total-area')} : {totalArea} {t('constants.hectare-unit')}
                            </Typography>
                        </div>
                        <Typography variant="body2">{t('encoding-rotation.total-area.increase')}</Typography>
                    </div>
                ) : (
                    <Typography mt={0.5} color="neutral" variant="body">
                        {t('constants.total-area')} : {totalArea} {t('constants.hectare-unit')}
                    </Typography>
                )}
                <Typography mt={0.5} color="neutral" variant="body">
                    {numberOfFields} {t('constants.field', { count: numberOfFields })}
                </Typography>
            </div>
        </>
    );
};
