import { Button } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { usePacImportHeaderStyles } from '@modules/encoding/modules/rotation/components/PacImportHeader/PacImportHeader.style';
import { IconUpload } from '@soil-capital/ui-kit/icons';
import { usePacImportHeaderLogic } from '@modules/encoding/modules/rotation/components/PacImportHeader/usePacImportHeaderLogic';
import { PacFileImportModal } from '../PacFileImportModal/PacFileImportModal';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { FieldAreaInfo } from '@modules/encoding/modules/rotation/components/FieldAreaInfo/FieldAreaInfo';

export const PacImportHeader = () => {
    const { t } = useTranslation();
    const { classes } = usePacImportHeaderStyles();
    const {
        currentSeasonId,
        hasFields,
        isFetchingFields,
        harvestYear,
        pacFileModalOpen,
        onPacFileModalOpen,
        onPacFileModalClose,
        handleOpenIntercomArticle,
    } = usePacImportHeaderLogic();
    const { readOnly } = useFarmSeasonReadOnly();

    return (
        <>
            <Typography variant="h3">
                {t('encoding-rotation.pac-file.contextual-panel.title')}{' '}
                <span className={classes.harvest_year}>{harvestYear}</span>
            </Typography>
            {hasFields ? (
                <FieldAreaInfo />
            ) : (
                <Typography mt={0.5} color="neutral" variant="body2">
                    {t('encoding-rotation.pac-file.contextual-panel.description', {
                        harvestYear,
                    })}
                </Typography>
            )}
            <Button
                className={classes.importButton}
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={<IconUpload style={{ color: 'white' }} className={classes.uploadIcon} />}
                onClick={onPacFileModalOpen}
                disabled={readOnly}
            >
                {t('encoding-rotation.pac-file.contextual-panel.import-button')}
            </Button>
            {hasFields && (
                <Typography variant="subcaption" color="GrayText" className={classes.caption}>
                    {t('encoding-rotation.map.localise')}
                </Typography>
            )}
            {!hasFields && !isFetchingFields && (
                <Button onClick={handleOpenIntercomArticle} className={classes.helpButton} variant="text" fullWidth>
                    {t('encoding-rotation.pac-file.contextual-panel.help-button')}
                </Button>
            )}
            <PacFileImportModal
                open={pacFileModalOpen}
                currentSeasonId={currentSeasonId}
                onClose={onPacFileModalClose}
            />
        </>
    );
};
