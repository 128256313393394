import { Trans, useTranslation } from 'react-i18next';
import { HelpBox, Stack } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useIntroductionContentPageLogic } from './useIntroductionContentPage.logic';
import { useIntroductionContentData } from './useIntroductionContentData';
import { ROTATION_PROGRESS_STATUSES, useIntroductionContentProgress } from './useIntroductionContentProgress';
import { FieldCropAssignationModal } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/FieldCropAssignationModal';
import { useIntroductionContentPageStyles } from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/IntroductionContentPage.style';
import { formatNumber } from '@shared/utils';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IntroductionContentPage = () => {
    const { t } = useTranslation();
    const { fieldDataList, isDataLoading, harvestYear } = useIntroductionContentData();
    const { classes } = useIntroductionContentPageStyles();

    const {
        currentFieldCropProgress,
        maxFieldCropProgress,
        maxFieldProgress,
        currentFieldProgress,
        currentFinalizationStepProgress,
        maxFinalizationStepProgress,
        progressStatus,
        needFinalization,
        currentCropDestinationProgress,
        maxCropDestinationProgress,
        isFieldCropAssignationDone,
    } = useIntroductionContentProgress();

    const {
        open,
        handleOpen,
        handleOpenWithFieldId,
        handleContinue,
        handleClose,
        handleRedirectToPac,
        shouldShowCropDestination,
        handleRedirectToCropDestination,
    } = useIntroductionContentPageLogic({
        status: progressStatus,
        maxCropDestinationProgress,
        isFieldCropAssignationDone,
    });

    return (
        <>
            <FieldCropAssignationModal open={open} onClose={handleClose} />
            <ContentPage
                Title={
                    <ContentPage.Title>
                        <Trans
                            i18nKey="encoding-rotation.introduction.title"
                            components={[
                                <span
                                    key={`encoding-rotation.introduction.title-${0}`}
                                    className={classes.harvestYear}
                                />,
                            ]}
                            values={{ harvestYear }}
                        />
                    </ContentPage.Title>
                }
                Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
                Action={
                    <ContentPage.Action onClick={handleContinue}>
                        {progressStatus === ROTATION_PROGRESS_STATUSES.NOT_STARTED
                            ? t('constants.start')
                            : t('constants.continue')}
                    </ContentPage.Action>
                }
                isLoading={isDataLoading}
            >
                <IntroProgressLayout
                    title={t('encoding-rotation.introduction.progress-title')}
                    leftSection={
                        <Stack spacing={1} mt={1}>
                            <IntroProgressItem
                                text={t('encoding-rotation.introduction.field-progress')}
                                progress={{ max: maxFieldProgress, current: currentFieldProgress }}
                                onClick={handleRedirectToPac}
                            />
                            <IntroProgressItem
                                text={t('encoding-rotation.introduction.crop-progress')}
                                progress={{ max: maxFieldCropProgress, current: currentFieldCropProgress }}
                                onClick={handleOpen}
                                disabled={progressStatus === ROTATION_PROGRESS_STATUSES.NOT_STARTED}
                            />
                            <IntroProgressItem
                                text={t('encoding-rotation.introduction.field-crop-progress')}
                                progress={{ max: maxFieldCropProgress, current: currentFieldCropProgress }}
                                disabled={progressStatus === ROTATION_PROGRESS_STATUSES.NOT_STARTED}
                            >
                                {fieldDataList.length > 0 && (
                                    <Stack spacing={1} mt={1}>
                                        {fieldDataList.map((field) => (
                                            <IntroProgressItem
                                                key={field.id}
                                                text={field.name}
                                                subText={
                                                    <div className={classes.subText}>
                                                        <div>
                                                            {field.crops.map((crop) => (
                                                                <div key={crop}>{crop}</div>
                                                            ))}
                                                        </div>
                                                        <div className={classes.fieldArea}>
                                                            {formatNumber(field.area, 2)} HA
                                                        </div>
                                                    </div>
                                                }
                                                image={field.image}
                                                progress={{
                                                    max: 1,
                                                    current: field.hasCrop ? 1 : 0,
                                                }}
                                                onClick={() => handleOpenWithFieldId(field.id)}
                                            />
                                        ))}
                                    </Stack>
                                )}
                            </IntroProgressItem>
                            {needFinalization && (
                                <IntroProgressItem
                                    text={t('encoding-rotation.introduction.field-crop-assignation-validation')}
                                    progress={{
                                        max: maxFinalizationStepProgress,
                                        current: currentFinalizationStepProgress,
                                    }}
                                    onClick={handleOpen}
                                    disabled={progressStatus === ROTATION_PROGRESS_STATUSES.NOT_STARTED}
                                />
                            )}
                            {shouldShowCropDestination && (
                                <IntroProgressItem
                                    text={t('encoding-rotation.introduction.crop-destination')}
                                    progress={{
                                        max: maxCropDestinationProgress,
                                        current: currentCropDestinationProgress,
                                    }}
                                    onClick={handleRedirectToCropDestination}
                                    disabled={!isFieldCropAssignationDone}
                                />
                            )}
                        </Stack>
                    }
                    rightSection={
                        <HelpBox>
                            <Typography variant="h3"> {t('encoding-rotation.introduction.help-title')}</Typography>
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-rotation.introduction.help-subtitle-1')}
                            </Typography>
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-rotation.introduction.help-description-1')}
                            </Typography>
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-rotation.introduction.help-subtitle-2')}
                            </Typography>
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-rotation.introduction.help-description-2')}
                            </Typography>
                        </HelpBox>
                    }
                />
            </ContentPage>
        </>
    );
};
